<template>
  <section id="fb-paper-meta-list">
    <el-form label-width="80px" style="min-width:700px">
      <el-form-item label="coursePrefix">
        <el-input v-model="coursePrefix" placeholder="TODO"></el-input>
      </el-form-item>
      <el-form-item label="labelIds">
        <el-input v-model="labelIds" placeholder="TODO"></el-input>
      </el-form-item>
      <div class="meta-result">
        <div v-for="(item, index) in sheetList" :key="index">
          <span> {{ item.id }} , {{ item.name }} </span> <br />
        </div>
      </div>
      <el-form-item>
        <el-button type="primary" @click="onQuery">查询</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { crawlerApi } from "@/api/crawlerApi";
import msgLogger from "@/util/msgLogger.js";
export default {
  data() {
    return {
      sheetList: [],
      coursePrefix: "jszgzhz",
      labelIds: "",
    };
  },
  mounted() {},
  methods: {
    onQuery() {
      if (!this.coursePrefix) {
        msgLogger.warn("coursePrefix 不允许为空");
        return;
      }
      crawlerApi.getFbPaperMetaList(this.coursePrefix, this.labelIds).then((ret) => {
        console.log("..", ret);
        let { code, data } = ret;
        if (code == 0 && data) {
          this.sheetList = data;
        }
      });
    },

    isNumeric(str) {
      if (typeof str != "string") return false; // we only process strings!
      return (
        !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      ); // ...and ensure strings of whitespace fail
    },
  },

  watch: {},
};
</script>

<style lang="less" scoped>
#fb-paper-meta-list {
  .meta-result {
    text-align: left;
  }
}
</style>
